import React from 'react';
import { Outlet } from 'react-router-dom';
// import Logo from '../../Images/logo.png'
import Logo from '../../Images/Sahayak Logo-1.png';
import Sahayak_Logo from '../../Images/Sahayak Logo-1.png'
import Footer from '../Footer';
import ProfileMenu from '../../Pages/CustomerPortal/ProfileMenu';



export const GrafdoerLayout = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const logo =  urlParams.has('logo') ? urlParams.get('logo') : localStorage.getItem('logo');
    return (
        <div className="">
            <div className="flex h-full w-full bg-pack-train bg-cover">
                {/* Sticky header */}
                <header className="fixed w-full z-50 bg-white">
                    <div className="mx-auto w-full  px-3 sm:px-6 lg:px-8">
                        <div className="flex justify-between pt-4 md:pt-2 sm:pt-8 sm:px-16 sm:text-center"> {/* Adjust padding for mobile */}
                            <div className="flex flex-col pb-2 items-start justify-start">
                                {/* Main Logo */}
                                <img src={logo} alt="Logo" className='w-28 sm:w-48 max-h-12 sm:max-h-9' /> {/* Resize the logo based on screen size */}

                                {/* Powered by section */}
                                <div className="flex items-end pb-2 ml-3 sm:ml-7 gap-1 font-extralight text-xs sm:text-sm">
                                    <span className="pb-1">Powered by</span>
                                    <img className="h-6 sm:h-7" src={Sahayak_Logo} alt="Sahayak Logo" /> {/* Adjust the size of the powered by logo */}
                                </div>
                            </div>{
                               
                                <ProfileMenu />    
                            }
                           
                        </div>
                        <div className="bg-themeBlue-4 dark:bg-gray-600 w-full h-0.5 -mt-8 md:mt-0"></div>
                    </div>
                </header>

                {/* Content with top margin to prevent overlap */}
                <div className="flex flex-col z-10 w-full mt-[40px]"> {/* Adjust margin according to header height */}
                    <div className="relative">{<Outlet />}</div>
                </div>
            </div>
            <div>{/* <Footer /> */}</div>
        </div>
    );
};

export const LogoutLayout = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const user =  JSON.parse(localStorage.getItem('userData'));
    const urlLogo = urlParams.has('logo') && urlParams.get('logo');
    const logo =  urlLogo || localStorage.getItem('logo') ||user.logo ;
    return (
        <div className="">
            <div className="flex h-full w-full bg-pack-train bg-cover">
                {/* Sticky header */}
                <header className="fixed w-full z-50 bg-white">
                    <div className="mx-auto w-full  px-3 sm:px-6 lg:px-8">
                        <div className="flex justify-between pt-4 md:pt-2 sm:pt-8 sm:px-16 sm:text-center"> {/* Adjust padding for mobile */}
                            <div className="flex flex-col pb-2 items-start justify-start">
                                {/* Main Logo */}
                                <img src={logo} alt="Logo" className='w-28 sm:w-48 max-h-12 sm:max-h-9' /> {/* Resize the logo based on screen size */}

                                {/* Powered by section */}
                                <div className="flex items-end pb-2 ml-3 sm:ml-7 gap-1 font-extralight text-xs sm:text-sm">
                                    <span className="pb-1">Powered by</span>
                                    <img className="h-6 sm:h-7" src={Sahayak_Logo} alt="Sahayak Logo" /> {/* Adjust the size of the powered by logo */}
                                </div>
                            </div>
                           
                        </div>
                        <div className="bg-themeBlue-4 dark:bg-gray-600 w-full h-0.5  md:mt-0"></div>
                    </div>
                </header>

                {/* Content with top margin to prevent overlap */}
                <div className="flex flex-col z-10 w-full mt-[40px]"> {/* Adjust margin according to header height */}
                    <div className="relative">{<Outlet />}</div>
                </div>
            </div>
            <div>{/* <Footer /> */}</div>
        </div>
    );
};