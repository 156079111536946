import { Dialog, DialogContent, DialogTitle, DialogActions, Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React from 'react';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import { deleteCustomerAddress } from '../../StateMgmt/Actions/customerActions';

const DeleteModal = (props) => {
    const { openPopup, setOpenPopup, icon, header, customerData } = props;
    const { address, id } = customerData
    console.log(customerData)

    const addressDeleteHandler = () => {
        deleteCustomerAddress(id);
        setOpenPopup(false)
    }
    const closeDialog = () => setOpenPopup(false);  // to close the modal when clicking anywhere outside

    return (
        <Dialog open={openPopup} onClose={closeDialog} fullWidth={true} maxWidth='sm' sx={design}>
            <Box width='600px' p={1} textAlign='center'>
                <DialogTitle className='flex justify-center space-x-2 text-[#851851] bg-[#f3e8ee]'>

                    {icon}
                    <Typography className='text-sm font-extrabold tracking-wide'>
                        {header}
                    </Typography>

                </DialogTitle>
                <DialogContent dividers className='flex flex-col justify-center space-y-3'>
                    {/* {userData && <Avatar {...stringAvatar(userData.firstname, userData.lastname)} sx={{ width: 70, height: 70, bgcolor: '#851851' }} />} */}
                </DialogContent>
                <DialogContent dividers className='flex flex-col justify-center space-y-3'>
                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                        <div>Are you sure want to remove this address ?</div>
                        {customerData && <div>{`${address}`} </div>}
                    </Typography>
                    <Typography variant="caption" className='text-red-600' style={{ fontWeight: 700 }}>
                        {customerData && <div>This action will not be undo later, you want to proceed ?</div>}
                    </Typography>
                </DialogContent>

                <DialogActions className='flex space-x-3'>
                    {customerData && <BtnDefault
                        variant="contained"
                        color="primary"
                        size="medium"
                        text="Delete Address"
                        onClick={addressDeleteHandler}
                    />}
                    <BtnOutlined
                        variant="outlined"
                        color="error"
                        size="medium"
                        text="Cancel"
                        onClick={() => setOpenPopup(false)}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default DeleteModal

const stringAvatar = (fname, lname) => {
    return {
        sx: {
            bgcolor: '#851851',
        },
        children: `${fname.split(' ')[0][0]}${lname.split(' ')[0][0]}`,
    };
}

const design = {
    '& .MuiDialogContent-root': {
        justifyContent: 'center',
        alignItems: 'center'
    },
    '& .MuiDialogActions-root': {
        justifyContent: 'center',
        alignItems: 'center'
    }
}
