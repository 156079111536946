import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import gridStyle from '../lib/Styles/grid_style';
import { setUserAddressId } from '../CommonFunctions/setAddressId';
import ActionBtns from './ActionBtns';


const CustomerProductDetails = ({ ProductData, setSelectedProduct, setOpenPopup, setAction, setProductInvoice }) => {
    const { state } = useLocation();
    const [pageSize, setPageSize] = useState(10);
    const [rowId, setRowId] = useState(null);
    const [rowData, setRowData] = useState({});
    const [filter, setFilter] = useState([]);
    setUserAddressId(state?.addresses[0]?.id);
    const [selectionModel, setSelectionModel] = useState([]);
    const [previousSelection, setPreviousSelection] = useState([]);
    const [selectProductJson, setSelectProductJson] = useState({});

    const handleSelectionModelChange = (newSelection) => {
        debugger
        setSelectionModel(newSelection)
        setPreviousSelection(newSelection)
        const newSelectionSet = newSelection.filter((element) => !previousSelection.includes(element));
        setSelectionModel(newSelectionSet);
        const productJson = ProductData.find((x) => x.customerProductId === newSelectionSet[0]);
        setSelectedProduct(productJson);
        console.log(productJson);
        setPreviousSelection(newSelectionSet)
    }


    const columns = useMemo(() => [
          {
            field: 'customerProductId',
            headerName: 'customerProductId',
            width: 0,
            headerAlign: 'center',
            align: 'center',
            hidden: true
        },
        {
            field: 'productSubCategory',
            headerName: 'Sub Category',
            width: 250,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 180,
            renderCell: params => <ActionBtns {...{ params, setOpenPopup, setAction, flag: 'Product Invoice', setProductInvoice, disableOptions: {
                view: true
            } }} />,
            headerAlign: 'center',
            headerClassName: 'table--header',
            align: 'center'
        },
    ], [rowId])
    // rowId is passed as a dependency in useMemo since we've to render the btn if there is a change in the row

    return (
        <>
            <Box
                sx={{ ...gridStyle, height: '55vh' }}>
                <DataGrid
                    columns={columns}
                    rows={ProductData}
                    getRowId={(row) => row.customerProductId}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 5,
                        bottom: params.isLastVisible ? 0 : 5
                    })}
                    onCellEditCommit={params => setRowId(params.id)}
                    onRowClick={params => { setRowId(params.id); setRowData(params.row) }}
                    filterModel={{
                        items: filter
                    }}
                    initialState={{
                        ...ProductData.initialState,
                        columns: {
                          ...ProductData.initialState?.columns,
                          columnVisibilityModel: {
                            customerProductId: false
                          },
                        },
                      }}
                    checkboxSelection
                    selectionModel={selectionModel}
                    onSelectionModelChange={handleSelectionModelChange}
                />
            </Box>
        </>
    )
}

export default CustomerProductDetails;

