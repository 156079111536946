import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaTools } from "react-icons/fa";
import { GiAutoRepair } from "react-icons/gi";
import { BsTelephonePlusFill } from "react-icons/bs";
import { BtnDefault } from '../../Components/BtnComponent';
import { FaHome } from "react-icons/fa";

import { getCustomerCalls } from '../../StateMgmt/Actions/customerActions';
import moment from 'moment';
import ModalOpener from '../../Components/ModalOpener';
import { getCustomerProfile } from '../../StateMgmt/Actions/userProfileActions';

const CallCards = ({ callStatus, productId }) => {
    const navigate = useNavigate()
    const customerID = localStorage.getItem('cust_id');
    const [callData, setCallData] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [action, setAction] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    const customerData = {
        productId: productId,
        custName: name,
        custPhone: phone
    }

    useEffect(() => {
        getCustomerCalls(callStatus, productId).then((data) => {
            setCallData(data.payload)
        });

        getCustomerProfile().then(({ payload: { userName, phoneNo } }) => {
            setName(userName);
            setPhone(phoneNo);
        });
    }, [openPopup])

    return (
        <div>
            <section>
                <div class="mb-8 px-2">
                    <div className='flex justify-between'>
                        <button
                            onClick={() => {
                                navigate('/customer/productList', { state: { customerId: customerID } })
                            }}
                            className="inline-flex justify-center gap-2 px-4 py-2 mb-4 font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                        >
                            <span className="font-medium text-lg">Home</span>
                            <div className='mt-1'>
                                <FaHome className='w-5 h-5' />
                            </div>
                        </button>

                        <button
                            onClick={() => {
                                setOpenPopup(true)
                                setAction('OpenCustomerCall')
                            }}
                            className="inline-flex justify-center gap-2 px-4 py-2 mb-4 font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                        >
                            <span className="font-medium text-lg">Open New Call</span>
                            <div className='mt-1'>
                                <BsTelephonePlusFill className='w-5 h-5' />
                            </div>
                        </button>

                        {/* <BtnDefault
                            variant="contained"
                            color="primary"
                            size="medium"
                            text="Home"
                            startIcon=<FaHome />
                            onClick={() => navigate('/customer/productList', { state: { customerId: customerID } })}
                        /> */}
                    </div>

                    <div class="mx-auto max-w-7xl text-gray-500">

                        <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-3">

                            {callData.map((item, i) => (
                                <div key={i} class="relative group overflow-hidden p-8 rounded-xl bg-white border border-gray-200 ">
                                    <div
                                        className={`inset-0 absolute aspect-video border rounded-full -translate-y-1/2 group-hover:-translate-y-1/4 duration-300 blur-2xl opacity-25 
                                                    ${item.status === 'CLOSED' || item.status === 'COMPLETED'
                                                ? 'bg-gradient-to-b from-green-500 to-white'
                                                : 'bg-gradient-to-b from-red-500 to-white'}`}>
                                    </div>

                                    <div class="relative">
                                        <div
                                            className={`border border-blue-500/10 flex relative rounded-lg before:rounded-[7px] before:absolute before:inset-0 before:border-t before:border-white before:shadow
                                             ${item.status === 'CLOSED' || item.status === 'COMPLETED'
                                                    ? 'before:from-green-100 before:bg-gradient-to-b'
                                                    : 'before:from-red-100 before:bg-gradient-to-b'}`}>
                                            <span className='mx-auto mt-1 text-2xl text-gray-900 font-semibold'>{item.status}</span>
                                        </div>
                                        <div className='flex justify-between'>
                                            <div class="mt-6 pb-6 ">
                                                <p class="text-sm text-gray-700">
                                                    {item.callId} | Created at : <span className='font-semibold text-gray-900'>{item.createdDate}</span>
                                                </p>
                                                <p className="text-md font-bold text-gray-700 relative group">
                                                    {item.product.length > 100 ? `${item.product.substring(0, 30)}...` : item.product}
                                                    <span className="absolute hidden group-hover:block w-auto bg-gray-700 text-white text-sm rounded-md p-2 -top-6 left-0 z-10">
                                                        {item.product}
                                                    </span>
                                                </p>
                                                <p class="text-md font-medium text-gray-700">
                                                    <span className='text-sm'>Visit Scheduled</span> : {moment(item.visitDateTime).format('LLLL')}
                                                </p>
                                            </div>
                                            <div className='mt-10'>
                                                {
                                                    item.status === 'CLOSED' || item.status === 'COMPLETED' ? <FaTools className='w-12 h-12' /> : <GiAutoRepair className='w-12 h-12' />
                                                }
                                            </div>
                                        </div>


                                        {/* <div class="flex gap-3 -mb-8 py-4 border-t border-gray-200 dark:border-gray-800">
                                            <a href="#" class="group rounded-xl  text-gray-700 bg-gray-200 hover:bg-gray-300/75 flex gap-1.5 items-center text-sm h-8 px-3.5 justify-center">
                                                <span className='text-gray-600 font-lg'>View Details</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m17 13l-5 5m0 0l-5-5m5 5V6"></path></svg>
                                            </a>
                                        </div> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            {action &&
                <ModalOpener
                    action={action}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    data={customerData}
                />
            }
        </div>
    )
}

export default CallCards




