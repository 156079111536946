import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import DashboardHeader from '../Headers/DashboardHeader';
import Exp_Sidebar from '../ExpSideNav';
import Footer from '../Footer';



const Layout = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        else if(!window.location.href.split('/').pop()){
            const user = JSON.parse(localStorage.getItem('userData'));
            if(!user) {
                navigate('/login'); 
                localStorage.clear();
                return;
            }
            if(user.role === 'Technician') navigate(`/technician`);
            if(user.role === 'Customer Executive') navigate(`/cust_complaints`);
            if(user.role === 'Admin') navigate(`/home`);
        }
    }, []);
    return (
        <div className='mb-auto '>
            <div className='flex h-full w-full bg-pack-train bg-cover '>
                {/* <Header /> */}
                <Exp_Sidebar />
                <div className='flex flex-col z-0 w-full' style={{height: '127vh', overflow: 'hidden'}}>
                    <div className='sticky px-2'>  <DashboardHeader /></div>
                    <div className='-z-10 mt-0 relative overflow-auto'>{<Outlet />}</div>
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>

    )
}

export default Layout