import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FaTools } from "react-icons/fa";
import { GiAutoRepair } from "react-icons/gi";
import CallCards from '../CallCards';

const CallTabs = () => {
    const location = useLocation();
    let ProductId = location?.state?.productId || '';
    let subCategory = location?.state?.subCategory || '';
    let range = location?.state?.range || '';
   
    const [openTab, setOpenTab] = useState(2);

    return (
        <>
            <div className="flex flex-col h-auto mt-16 sm:mt-32 mx-auto max-w-7xl px-4">
                <div className="w-full mb-4 p-3 bg-gray-100 text-center text-xl text-zinc-600 font-bold">
                    {subCategory ?? '' / range ?? ''}
                </div>
                <ul className="flex list-none pb-4 flex-row mb-4 gap-4" role="tablist">
                    <li className="last:mr-0 flex-auto text-center cursor-pointer">
                        <div className={"font-bold border px-1 py-3 shadow-md shadow-themeBlue-5 block hover:no-underline " + (openTab === 2 ? "bg-" + "gray-800" : "bg-white hover:bg-[#ebf4fd]")}
                            onClick={e => {
                                e.preventDefault();
                                setOpenTab(2);
                            }}
                            data-toggle="tab"
                            href="#link1"
                            role="tablist"
                        >
                            <div className="flex justify-center">
                                <div className={'rounded-full h-auto w-auto flex items-center justify-center'}>
                                    <GiAutoRepair className={'text-2xl' + (openTab === 2 ? " text-white" : " text-gray-800")} />
                                </div>
                                <div className='pl-4'>
                                    <span className={'text-lg uppercase font-normal ' + (openTab === 2 ? " text-white" : " text-gray-800")}>
                                        ACTIVE CALLS
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="-mb-px last:mr-0 flex-auto text-center cursor-pointer">
                        <div className={"text-xs font-bold px-1 pr-2 pl-3 py-3 shadow-md shadow-themeBlue-5 border block hover:no-underline " + (openTab === 1 ? "bg-" + "gray-800" : "bg-white hover:bg-[#ebf4fd]")}
                            onClick={e => {
                                e.preventDefault();
                                setOpenTab(1);
                            }}
                            data-toggle="tab"
                            href="#link1"
                            role="tablist"
                        >
                            <div className="flex justify-center">
                                <div className={'rounded-full h-auto w-auto flex items-center justify-center'}>
                                    <FaTools className={'text-2xl' + (openTab === 1 ? " text-white" : " text-gray-800")} />
                                </div>
                                <div className='pl-4'>
                                    <span className={'text-lg uppercase font-normal ' + (openTab === 1 ? " text-white" : " text-gray-800")}>
                                        HISTORY
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>

                </ul>
                <div className="shadow-md">
                    <div className="px-4 py-2  bg-white">
                        <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                            <CallCards callStatus={false} productId={ProductId} />
                        </div>
                        <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                            <CallCards callStatus={true} productId={ProductId} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CallTabs