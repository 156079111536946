import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdDelete, MdOutlineAddLocation } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import { IoLocation } from "react-icons/io5";
import ModalOpener from '../../Components/ModalOpener';
import { deleteCustomerAddress, getCustomeraddresses } from '../../StateMgmt/Actions/customerActions';

const CustomerAddresses = () => {
    const navigate = useNavigate()

    const [openPopup, setOpenPopup] = useState(false);
    const [action, setAction] = useState('');
    const [addressData, setAddressData] = useState([]);
    const customerID = localStorage.getItem('cust_id');
    const [deleteAddress, setDeleteAddress] = useState({});

    useEffect(() => {
        getCustomeraddresses().then((data) => {
            console.log(data.payload)
            setAddressData(data.payload)
        });
    }, [openPopup])

    const removeAddress = (id) => {
        const delAddress = addressData.find(address => address.id === id);
        console.log(delAddress)
        setDeleteAddress(delAddress)
        setOpenPopup(true)
        setAction('Delete Address')
    }

    return (
        <section>
            <div className='flex absolute mt-24 right-0 sm:mr-28 items-center justify-end rounded-lg gap-2 p-2 w-56 ml-2 '>
                {/* <button
                    onClick={() => {
                        navigate('/customer/productList', { state: { customerId: customerID } })
                    }}
                    className="inline-flex justify-center gap-2 px-4 py-2 mb-4 font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                >
                    <span className="font-medium text-lg">Home</span>
                    <div className='mt-1'>
                        <FaHome className='w-5 h-5' />
                    </div>
                </button> */}
                <button
                    onClick={() => {
                        setOpenPopup(true)
                        setAction('AddNewAddress')
                    }}
                    className="inline-flex justify-center gap-2 w-full px-4 py-2 font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                >
                    <span className="font-medium text-lg">Add Address</span>
                    <div className='mt-1'>
                        <MdOutlineAddLocation className='w-5 h-5' />
                    </div>
                </button>

            </div>
            <div class="py-32 px-2">
                <div class="mx-auto max-w-7xl text-gray-500">
                    <div class="mt-12 grid sm:grid-cols-2 lg:grid-cols-3 gap-3">
                        {
                            addressData.map((address, i) => (
                                <div key={i} class="relative group overflow-hidden p-8 rounded-xl bg-white border border-gray-300">
                                    <div aria-hidden="true" class="inset-0 absolute aspect-video border rounded-full -translate-y-1/2 group-hover:-translate-y-1/4 duration-300  blur-2xl opacity-25"></div>
                                    <div class="relative">
                                        <div className='flex justify-between'>
                                            <div class="mt-6 pb-6 ">
                                                <p class="text-lg font-bold text-gray-700">
                                                    {address.addressLine}
                                                </p>
                                                <p class="text-md font-medium text-gray-700">
                                                    {address.city}, {address.pinCode}
                                                </p>
                                                <p class="text-sm text-gray-700">
                                                    {address.state}, {address.country}
                                                </p>
                                            </div>
                                            <div className='mt-10'>
                                                <IoLocation className='w-12 h-12' />
                                            </div>
                                        </div>


                                        <div class="flex gap-3 -mb-8 py-4 border-t border-gray-200 cursor-pointer">
                                            <div onClick={() => removeAddress(address.id)} class="group rounded-xl  text-gray-700 bg-gray-200 hover:bg-gray-300/75 flex gap-1.5 items-center text-sm h-8 px-3.5 justify-center">
                                                <span className='text-gray-600 font-lg'>Delete</span>
                                                <MdDelete className='w-5 h-5' />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                        {/* <div class="relative group overflow-hidden p-8 rounded-xl bg-white border border-gray-300 dark:border-gray-800 dark:bg-gray-900">
                            <div aria-hidden="true" class="inset-0 absolute aspect-video border rounded-full -translate-y-1/2 group-hover:-translate-y-1/4 duration-300  blur-2xl opacity-25"></div>
                            <div class="relative">
                                <div className='flex justify-between'>
                                    <div class="mt-6 pb-6 ">
                                        <p class="text-lg font-bold text-gray-700">
                                            Vihaan galaxy 3023
                                        </p>
                                        <p class="text-md font-medium text-gray-700">
                                            Greater Noida, 233223
                                        </p>
                                        <p class="text-sm text-gray-700">
                                            Uttar Pradesh, India
                                        </p>
                                    </div>
                                    <div className='mt-10'>
                                        <IoLocation className='w-12 h-12' />
                                    </div>
                                </div>


                                <div class="flex gap-3 -mb-8 py-4 border-t border-gray-200 dark:border-gray-800">
                                    <a href="#" download="/" class="group rounded-xl  text-gray-700 bg-gray-200 hover:bg-gray-300/75 flex gap-1.5 items-center text-sm h-8 px-3.5 justify-center">
                                        <span className='text-gray-600 font-lg'>Delete</span>
                                        <MdDelete className='w-5 h-5' />
                                    </a>

                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            {action &&
                <ModalOpener
                    action={action}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    id={customerID}
                    data={deleteAddress}
                />
            }
        </section>
    )
}

export default CustomerAddresses