import React from 'react';
import { Dialog, DialogContent, DialogTitle, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import AddProductTabs from '../AddProductTabs';

const design = {
    '& .MuiDialogContent-root': {
        justifyContent: 'center',
        alignItems: 'center',
    },
}


const AddModal = (props) => {
    const { openPopup, setOpenPopup, formLayout, icon, header, id } = props;
    const closeDialog = ( event,reason) => {
        if (reason === "backdropClick") {
        } else {
            setOpenPopup(false);
        }
      };
      const handleClose = () => {
        setOpenPopup(false);
      };
    // const handleButtonClick = () => setOpenPopup(true);
    return (
        <Dialog open={openPopup}  onClose={closeDialog} fullWidth maxWidth="md" sx={design} disableEscapeKeyDown >
            <Box p={1} textAlign='center'>
                <DialogTitle className='flex flex-col text-themeBlue-2 bg-themeBlue-4'>
                    <div className='flex space-x-2 justify-center'>
                        {icon}
                        <Typography className='text-sm font-extrabold tracking-wide'>
                            {header}
                        </Typography>
                    </div>
                    {id && <div className='text-[#b67497] text-xs font-bold tracking-wide'>{id}</div>}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.white,
                    })}
                    >
                    <CloseIcon />
                    </IconButton>
                <DialogContent dividers className='justify-center space-y-2' >
                    {formLayout}
                </DialogContent>
            </Box>
        </Dialog>
    )
}

export default AddModal