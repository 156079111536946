import api from "../utils/axios";


const GetCustProductOptions = async () => {
    const res = await api.get('/api/v1/customer/productFilter');
    const productData = res.data.data;

    const transformData = (input) => {
        return Object.keys(input).map((category) => ({
            label: category,
            subCategories: Object.keys(input[category]).map((subcategory) => ({
                label: subcategory,
                range: input[category][subcategory].map((item) => ({
                    subCategoryName: item.subCategoryName,
                    rangeName: item.rangeName,
                    label: `${category}/${subcategory}/${item.rangeName}`, // Construct the desired label
                    id: item.id
                }))
            }))
        }));
    };

    const transformedData = transformData(productData);
    const productOptions = {
        category: transformedData,
    };
    return productOptions;
}

export default GetCustProductOptions;