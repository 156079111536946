import React, { useState } from 'react';
import { MdEdit } from "react-icons/md";
import { editCustomer } from '../../StateMgmt/Actions/customerActions';


const EditableText = ({ initialName }) => {
    const customerID = localStorage.getItem('cust_id');
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState(initialName);
    const reqBody = {}
    // Toggle between editing and viewing mode

    const toggleEdit = async () => {
        setIsEditing(!isEditing);
        reqBody.id = customerID;
        reqBody.name = name;
        await editCustomer(reqBody);
    };

    // Handle name input change
    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    return (
        <div className="pt-2">
            {isEditing ? (
                // Edit mode: show an input field
                <div className="flex items-center justify-center">
                    <input
                        type="text"
                        value={name}
                        onChange={handleNameChange}
                        className="border border-gray-300 rounded-lg px-2 py-1 text-lg font-semibold"
                    />
                    <button
                        onClick={toggleEdit}
                        className="ml-2 text-blue-500 hover:underline"
                    >
                        Save
                    </button>
                </div>
            ) : (
                // View mode: show the name and the "Edit" button
                <div className="flex items-center justify-center ml-4">
                    <p className="text-lg font-semibold">{name}</p>
                    <button
                        onClick={toggleEdit}
                        className="ml-2 text-blue-500 hover:underline"
                    >
                        <MdEdit />
                    </button>
                </div>
            )}
        </div>
    );
};

export default EditableText;

// Usage example
// const App = () => {
//     return (
//         <div className="container">
//             <EditableName initialName="John Doe" />
//         </div>
//     );
// };

// export default App;
