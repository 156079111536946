import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DropdownBtn from '../../Components/DropdownBtn';
import ModalOpener from '../../Components/ModalOpener';
import { GetCustomerProducts } from '../../StateMgmt/Actions/productActions';
import { BtnDefault } from '../../Components/BtnComponent';
import { FaHome } from "react-icons/fa";
import { MdQrCodeScanner, MdOutlineAdd } from "react-icons/md";


const ProductList = () => {
    const location = useLocation();
    const navigate = useNavigate()

    const manualAdd = () => {
        setAction('Add Manually');
        setOpenPopup(true);
    }

    const scanQRAdd = () => {
        setAction('Scan QR');
        setOpenPopup(true);
    }

    const [openPopup, setOpenPopup] = useState(false);
    const [action, setAction] = useState('');
    const [productData, setProductData] = useState([]);


    useEffect(() => {
        GetCustomerProducts().then((data) => {
            console.log(data.payload.data);
            setProductData(data.payload.data);

            // dispatch(product)
        });
    }, [openPopup])

    const openCalls = (id, subCategoryName, rangeName) => {
        navigate('/customer/customerCalls', { state: { productId: id, subCategory: subCategoryName, range: rangeName } })
    }

    return (
        <div className='relative'>
            {/* <div className='flex absolute mt-10 right-0 sm:mr-28 items-center justify-between rounded-lg gap-2 p-2 w-56 ml-2 '>
                <DropdownBtn title='Add Product' dropdownArray={dropdownOptions} />
            </div> */}
            <div className="flex flex-col text-gray-600 mt-8">
                <div className='flex justify-end pr-5 gap-4'>
                    <button
                        onClick={() => scanQRAdd()}
                        className="inline-flex justify-center right-0 gap-2 px-4 py-2 mt-10 font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 "
                    >
                        <span className="font-medium text-lg">Scan QR</span>
                        <div className='mt-1'>
                            <MdQrCodeScanner className='w-5 h-5' />
                        </div>
                    </button>
                    <button
                        onClick={() => manualAdd()}
                        className="inline-flex justify-center right-0 gap-2 px-4 py-2 mt-10 font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 "
                    >
                        <span className="font-medium text-lg">Add Manually</span>
                        <div className='mt-1'>
                            <MdOutlineAdd className='w-5 h-5' />
                        </div>
                    </button>
                </div>

                <div className="px-5 pt-4 mx-auto max-w-[110rem] w-full">

                    {productData.length?
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                            {productData.map((item, i) => (
                                <div
                                    onClick={() => openCalls(item.ProductId, item.subCategoryName, item.rangeName)}
                                    key={i}
                                    className="cursor-pointer hover:scale-105 duration-500 border border-gray-300"
                                >
                                    <div className="flex items-center justify-between p-4 rounded-lg bg-white  hover:border-gray-400">
                                        <div className="w-2/3">
                                            <h2 className="text-gray-900 text-lg font-bold">
                                                {item.subCategoryName} / {item.rangeName}
                                            </h2>
                                            <p className="text-xs font-semibold text-gray-400">
                                                {item.dealerName} - {item.invoiceDate ?? 'NA'}
                                            </p>
                                        </div>
                                        <div className="w-32 h-32 flex justify-center items-center">
                                            <img
                                                className="w-full h-full object-cover"
                                                src={item.image || "https://sahayak-uat.s3.ap-south-1.amazonaws.com/uat_Profile/Profile-4-4-1ee8fe20-02c5-4d17-b7e3-820307e2ca84.jpeg"}
                                                alt="product img"
                                            />
                                        </div>



                                    </div>
                                    <div class="flex border-gray-200 w-full ml-4 mb-2">
                                        <div class="group rounded-md  text-gray-700 bg-gray-200 hover:bg-gray-300/75 flex gap-1.5 items-center text-sm h-8 px-3.5 justify-center">
                                            <span className='text-gray-600 font-lg font-semibold'>Register Complaint</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        <div className="flex items-center justify-center p-6 border ">
                            <span className=" font-semibold">No products to added, Please add product by either scanning the QR on product or add Manually</span>
                        </div>
                    }



                </div>
            </div>

            {action &&
                <ModalOpener
                    action={action}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                // data={productData}
                />
            }
        </div>

    )
}

export default ProductList