import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaUser } from "react-icons/fa";
import { AiFillProfile } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";
import EditableText from './EditableText';
import { getCustomerProfile } from '../../StateMgmt/Actions/userProfileActions';
import userImage from '../../Assets/user.jpg'



const ProfileSetting = () => {
    const navigate = useNavigate()

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [uData, setUData] = useState({});
    const [userImg, setUserImg] = useState();
    const customerID = localStorage.getItem('cust_id');
    useEffect(() => {

        getCustomerProfile().then(({ payload: { userName, phoneNo, userData } }) => {
            setUData(userData)
            setName(userName);
            setPhone(phoneNo);
            // setUserImg(userData?.profilePic || user_img);
        });
    }, [])
    return (

        <div>

            <div class="bg-white max-w-md mx-auto rounded overflow-hidden mt-40 border border-gray-300">
                <div class="text-center p-6 border-b">
                    <div class="h-24 w-24 rounded-full mx-auto">
                        <FaUser className='h-24 w-24' />
                    </div>
                    <p class="pt-2 text-lg font-semibold">
                        {name && <EditableText initialName={name} />}
                    </p>
                    <p class="text-sm text-gray-600">
                        {phone}
                    </p>
                    {/* <div class="mt-8">
                        <a href="#" class="border rounded-full py-3 px-5 text-sm font-semibold text-gray-700">Manage your account</a>
                    </div> */}
                </div>
                <div class="border-b">

                    {/* <a href="#" class="px-6 py-3 hover:bg-gray-200 flex">
                        <div className=''>
                            <AiFillProfile className='w-10 h-10 rounded' />
                        </div>
                        <div class="pl-3">
                            <p class="text-md font-semibold">
                                Personal Details
                            </p>
                            <p class="text-sm text-gray-600">
                                Credentials, Security ,etc
                            </p>
                        </div>
                    </a> */}

                    <a href="/customer/manageAddress" class="px-6 py-3 hover:bg-gray-200 flex">
                        <div className=''>
                            <MdLocationOn className='w-10 h-10 rounded' />
                        </div>
                        <div class="pl-3">
                            <p class="text-md font-semibold">
                                Addresses
                            </p>
                            <p class="text-sm text-gray-600">
                                Manage your addresses
                            </p>
                        </div>
                    </a>
                </div>
                <div class="border-b">

                    <div class="px-6 py-4 text-center cursor-pointer">
                        <div onClick={() => { localStorage.clear(); navigate('/customer/thankyou'); }} class="border rounded py-2 px-4 text-sm font-semibold text-gray-70 ">
                            Sign out
                        </div>
                    </div>
                </div>

                {/* <div class="px-6 py-4">
                    <span class="inline-block rounded-full px-3 py-1 text-xs font-semibold text-gray-600 mr-2">Privacy Policy</span>
                    <span class="inline-block rounded-full px-3 py-1 text-xs font-semibold text-gray-600 mr-2">Terms of Service</span>
                </div> */}
            </div>
        </div>
    )
}

export default ProfileSetting