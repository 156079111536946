import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import BtnOutlined, { BtnDefault } from '../../Components/BtnComponent';
import downloadGeneratedQR from '../../CommonFunctions/downloadGeneratedQR';
import { Stack } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';


const GenerateQRForm = ({ setOpenPopup, productId }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();
    const onSubmit = async (data) => {
        data.count = +data.count;
        const reqBody = data;
        reqBody.productId = +productId;
        reqBody.qrType = qrType;
        reqBody.pageCount = pageCount;
        const resData = await downloadGeneratedQR(reqBody)
        console.log(resData)
        window.myGlobalVariable = { user: (window.myGlobalVariable?.reload ?? 0)+1};
        setOpenPopup(false)

    }
    const qrOptions = [{
            label: 'Qr Image PDF',
            value: 'PDF'
        },
        {
            label: 'Qr url list csv',
            value: 'CSV'
        }
    ];
  
    const pageOption = [{label: '1'}, {label: '2'}, {label: '3'}, {label: '4'}];
    const [qrType, SetQrType] = useState(qrOptions[0].value);
    const [pageCount, SetPageCount] = useState(+pageOption[0].label);
    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-3/3'>
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <Stack spacing={2} sx={{ color: '#851851' }}>
                <Autocomplete
                        options={qrOptions}
                        defaultValue={qrOptions[0]}
                        disableClearable
                        onChange={(e, value) => { SetQrType(value.value) }}
                        renderInput={(params) => (
                            <CustomizeTextField
                                {...params}
                                label='Select prefred mode'
                                name='qrType'
                            // {...register("qrType", { required: 'Please select atleast one option' })}
                            // error={Boolean(errors.qrType)}
                            // helperText={errors.qrType?.message}
                            />
                        )}

                    />
                </Stack>
                <Stack spacing={2} sx={{ color: '#851851' }}>
                    {
                    qrType === 'PDF' ? 
                        <Autocomplete
                        options={pageOption}
                        defaultValue={pageOption[0]}
                        disableClearable
                        onChange={(e, value) => { SetPageCount(+value.label) }}
                        renderInput={(params) => (
                            <CustomizeTextField
                                {...params}
                                label='Select number pf page'
                                name='count'
                            // {...register("qrType", { required: 'Please select atleast one option' })}
                            // error={Boolean(errors.qrType)}
                            // helperText={errors.qrType?.message}
                            />
                        )}

                    />
                    : 
                    <CustomizeTextField
                        type="number"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        placeholder='Enter QR count'
                        variant='outlined'
                        label='Enter the number of QR to be generated'
                        name='count'
                        {...register("count", { required: 'Number is required' })}
                        error={Boolean(errors.count)}
                        helperText={errors.count?.message}
                    />
                    }
                </Stack>



            </div>

            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Generate"
                    type="submit"
                />
                <BtnOutlined
                    variant="outlined"
                    color="error"
                    size="medium"
                    text="Cancel"
                    onClick={() => setOpenPopup(false)}
                />
            </div>
        </form>
    )
}

export default GenerateQRForm