import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import BtnOutlined, { BtnDefault } from '../../../Components/BtnComponent';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { addCustomerCall, addNewCustomerCall, getCustomeraddresses } from '../../../StateMgmt/Actions/customerActions';
import { errorMessage } from '../../../utils/utils';
import ModalOpener from '../../../Components/ModalOpener';


const AddCustomerCall = (props) => {


    const { setOpenPopup, customerData } = props;
    const { custPhone, custName, addresses, productId } = customerData;
    const [vdtValue, setVdtValue] = useState(dayjs());
    const [action, setAction] = useState('');
    const [openAddressForm, setOpenAddressForm] = useState(false);
    const [softRelaod, setSoftReaload] = useState(new Date());
    // const [productId, setProductId] = useState(null);
    // const [addressId, setAddressId] = useState(addresses[0]?.id);
    const [addressId, setAddressId] = useState('');
    const [address, setAddress] = useState('');
    const [addressList, setAddressList] = useState([])
    const customerID = localStorage.getItem('cust_id');

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();


    useEffect(() => {
        getCustomeraddresses().then((data) => {
            const addressArray = data.payload.map(({ address, id }) => ({
                label: address,
                addressId: id
            }));
            setAddressList(addressArray)
        });


    }, [softRelaod])

    const handleSelectAddress = (_, value, reason) => {
        console.log(value.addressId)
        setAddressId(value.addressId);
    }

    const onSubmit = async (data) => {
        const reqBody = data;
        reqBody.visitDateTime = dayjs(vdtValue).format();
        reqBody.addressId = +addressId;
        // reqBody.customerId = id;
        reqBody.productId = productId;
        if (!addressId) errorMessage('No address selected')
        else {
            await addNewCustomerCall(reqBody);
            setOpenPopup(false);
        }
        // console.log(reqBody);
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <div className='flex flex-col gap-3'>
                    <CustomTextField
                        disabled
                        className='w-full'
                        defaultValue={custName ?? ''}
                        variant='outlined'
                        label='Customer Name'
                        name='firstName'
                    />
                </div>
                <CustomTextField
                    disabled
                    className='w-full'
                    defaultValue={custPhone ?? ''}
                    variant='outlined'
                    label='Phone'
                    name='phone'
                />
            </div>
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <div className='flex flex-col'>
                    <Stack spacing={2} sx={{ color: '#851851' }}>
                        <Autocomplete
                            // error={Boolean(errors.role)}
                            disableClearable
                            onChange={handleSelectAddress}
                            options={addressList}
                            // defaultValue={_.isEmpty(selectedProduct) ? productList[0] : selectedProduct}
                            renderInput={(params) => (
                                <CustomTextField
                                    {...params}
                                    label='Select Address'
                                    name='address'
                                    {...register("address", { required: 'Please select an address' })}
                                    error={Boolean(errors.address)}
                                    helperText={errors.address?.message}
                                // onChange={handleAutocompleteTextChange}
                                />

                            )}

                        />
                    </Stack>
                    <span
                        onClick={() => {
                            setOpenAddressForm(true)
                            setAction('AddNewAddress')
                        }}
                        className='flex justify-start mt-1 text-sm font-semibold text-[#153b8c] cursor-pointer'>
                        + ADD ADDRESS
                    </span>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label="Visiting Date/Time"
                        value={vdtValue}
                        onChange={(newValue) => {
                            setVdtValue(newValue);
                        }}
                        minDate={dayjs()}
                        renderInput={(params) => (
                            <CustomTextField
                                {...params}
                                name='visitDateTime'
                            />
                        )}
                    />
                </LocalizationProvider>
            </div>

            <CustomTextField
                placeholder="Describe your request"
                variant='outlined'
                multiline
                rows={2}
                label='Request Description'
                name='jobDescription'
                {...register("jobDescription", { required: false })}
            />

            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Open New Call"
                    type="submit"
                />
                <BtnOutlined
                    variant="outlined"
                    color="error"
                    size="medium"
                    text="Cancel"
                    border='#016fe2'
                    onClick={() => setOpenPopup(false)}
                />
            </div>

            {action &&
                <ModalOpener
                    action={action}
                    openPopup={openAddressForm}
                    setOpenPopup={setOpenAddressForm}
                    setSoftReaload={setSoftReaload}
                    id={customerID}
                />
            }
        </form>

    )
}

export default AddCustomerCall

const CustomTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#153b8c',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#153b8c',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#016fe2',
        },
        '&:hover fieldset': {
            borderColor: '#153b8c',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#153b8c',
        },
    },
});

const radioCss = {
    color: '#153b8c',
    '&.Mui-checked': {
        color: '#153b8c',
    },
};
