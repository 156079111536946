import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { FaUser } from "react-icons/fa";

import { getCustomerProfile } from '../../StateMgmt/Actions/userProfileActions';
import userImage from '../../Assets/user.jpg'
import _ from 'lodash';


const ProfileMenu = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const navigate = useNavigate()
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [uData, setUData] = useState({});
    const [userImg, setUserImg] = useState();
    const customerID = localStorage.getItem('cust_id');

    const menuRef = useRef(null); // Create a reference for the menu

    useEffect(() => {
        if (_.isNil(localStorage.getItem('token'))) return;

        getCustomerProfile().then(({ payload: { userName, phoneNo, userData } }) => {
            setUData(userData)
            setName(userName);
            setPhone(phoneNo);
            // setUserImg(userData?.profilePic || user_img);
        });
    }, [toggleMenu])

    // Handle outside click
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setToggleMenu(false); // Close the menu if clicked outside
            }
        };

        // Add the event listener for clicks
        document.addEventListener('mousedown', handleOutsideClick);

        // Cleanup the event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div class="flex justify-center items-center">
            <div class="relative inline-block mb-14 md:mb-0" ref={menuRef}>

                <button
                    onClick={() => setToggleMenu(!toggleMenu)}
                    className="relative z-10 flex items-center p-2 text-sm bg-white border border-transparent rounded-full focus:border-blue-500 focus:ring-opacity-40 dark:focus:ring-opacity-20 focus:ring-blue-300 dark:focus:ring-blue-400 focus:ring dark:bg-gray-800 focus:outline-none">
                    {/* <img src={userImg ?? userImage} className="w-10 h-10 rounded-full object-cover" /> */}
                    <FaUser className='w-8 h-8' />

                </button>



                {toggleMenu &&
                    <div class="absolute right-0 z-20 w-56 py-2 mt-2 overflow-hidden bg-white rounded-md shadow-xl dark:bg-gray-800">
                        <a href="#" class="flex items-center p-3 -mt-2 text-sm text-gray-600 transition-colors duration-200 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                            <FaUser className='w-8 h-8' />
                            <div class="mx-1 ml-7">
                                <h1 class="text-sm font-semibold text-gray-700 dark:text-gray-200">{name}</h1>
                                <p class="text-sm text-gray-500 dark:text-gray-400">{phone}</p>
                            </div>
                        </a>

                        <div class="border-gray-200 dark:border-gray-700 ">

                            <a href='/customer/profileSetting' class="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-200 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                                view profile
                            </a>
                            <div class="border-gray-200 dark:border-gray-700 ">
                                <div
                                    onClick={() => {
                                        navigate('/customer/productList', { state: { customerId: customerID } })
                                    }}
                                    class="block cursor-pointer px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-200 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                                    Home
                                </div>
                                <div class="border-gray-200 dark:border-gray-700 ">
                                    <div
                                        onClick={() => { localStorage.clear(); navigate('/customer/thankyou'); }}
                                        class="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors cursor-pointer duration-200 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                                    >
                                        Sign Out
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default ProfileMenu