import { successResponse } from '../../utils/utils';
import api from "../../utils/axios";
var qs = require('qs');


export const GetAllProducts = async (filter) => {
    let url = '/api/v1/product/getProductList';
    if (filter) url += `?filter=${filter}`;
    const res = await api.get(url);
    return {
        type: "GET_ALL_PRODUCTS",
        payload: res.data
    }
};

export const GetCustomerProducts = async (filter) => {
    let url = '/api/v1/customer/products';
    const res = await api.get(url);
    return {
        type: "GET_CUSTOMER_PRODUCTS",
        payload: res.data
    }
};

export const GetProductCategories = async () => {
    let url = '/api/v1/filter/product/getCategories';
    const res = await api.get(url);
    console.log(res.data)
    return {
        type: "GET_PRODUCT_CATEGORIES",
        payload: res.data
    }
};

export const addNewProductCategory = async (data) => {
    const res = await api.post('/api/v1/filter/product/addCategory', data);
    successResponse("Category created successfully!");
    return {
        type: "ADD_NEW_PRODUCT_CATEGORY",
        payload: res.data.data
    }
};

export const editProductCategory = async (data) => {
    const res = await api.post('/api/v1/filter/product/editCategory', data);
    successResponse("Category updated successfully!");
    return {
        type: "EDIT_PRODUCT_CATEGORY",
        payload: res.data.data
    }
};

export const GetProductBrands = async () => {
    let url = '/api/v1/filter/product/getBrands';
    const res = await api.get(url);
    console.log(res.data)
    return {
        type: "GET_PRODUCT_BRANDS",
        payload: res.data
    }
};

export const addProductBrand = async (data) => {
    const res = await api.post('/api/v1/filter/product/addBrand', data);
    successResponse("Brand added successfully!");
    return {
        type: "ADD_NEW_BRAND",
        payload: res.data.data
    }
};

export const editProductBrand = async (data) => {
    const res = await api.post('/api/v1/filter/product/editBrand', data);
    successResponse("Brand updated successfully!");
    return {
        type: "EDIT_PRODUCT_BRAND",
        payload: res.data.data
    }
};


export const addNewProduct = async (data) => {

    const res = await api.post('/api/v1/product/add', data);
    successResponse("Product added successfully!");
    return {
        type: "ADD_NEW_PRODUCT",
        payload: res.data.data
    }
};

export const editProduct = async (data) => {

    const res = await api.post('/api/v1/product/update', data);
    successResponse("Product detail updated successfully!");
    return {
        type: "EDIT_PRODUCT",
        payload: res.data.data
    }
};

export const deleteProduct = async (id) => {
    const res = await api.post('/api/v1/product/delete', { productId: id });
    successResponse("Product deleted successfully!");

    return {
        type: "DELETE_PRODUCT",
        payload: res.data.data
    }
};