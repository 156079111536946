import React, { useState, useEffect } from 'react';
import Html5QrcodePlugin from './Html5QrcodePlugin.js';
import api from '../../utils/axios.js';
import { useNavigate } from 'react-router-dom';
import { errorMessage } from '../../utils/utils.js';

const qrConfig = { fps: 15, qrbox: { width: 300, height: 300 } };

const ReadQR = ({ setResult, setOpenPopup }) => {
  const [data, setData] = useState("No result");
  const [selected, setSelected] = useState(false);
  const navigate = useNavigate();
  const onNewScanResult = (decodedText) => {
    if(selected) return;
    console.log("App [result]", decodedText);
    console.log(decodedText);
    if (!decodedText.includes('api/v1/auth/customer/scanProduct')) {
      errorMessage('Please select a valid QR');
    }
    setData(decodedText);
    setSelected(true);
    debugger
    
    api.get(decodedText).then((result)=>{
      setData("No result");
      navigate('/customer/productInfo', { state: { productData: result.data.data } });
    });
};
const onErrorHandle = (e) =>{
  // console.log(e)
  // if(selected) return;
  // errorMessage(e);
  // setSelected(true);
}
  return (
    <div>

      <div style={{ position: "relative" }}>
        <div id="reader" width="100%" />
        <Html5QrcodePlugin
                    fps={10}
                    // qrbox={{ width: 350, height: 350 }}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                    // qrCodeErrorCallback={onErrorHandle}
        />
    
      </div>
      <p>{data}</p>
    </div>
  )
}

export default ReadQR